@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  justify-content: center;
  background: lightblue url("../public/bg.jpg") no-repeat fixed center;
  background-size: cover;
}

h1,
p {
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

h1 {
  color: #86c0f3;
  -webkit-text-stroke: 1px rgb(82 100 122);
}

p {
  color: whitesmoke;
  padding: 2%;
}

#root {
  width: 0;
  max-height: 0;
  align-items: center;
  justify-content: center;
  border: 3px double #7dadd6;
  border-radius: 10px;
  background: rgb(18 18 46 / 50%);
  overflow: hidden;
  animation: expandWidth 1s forwards, expandHeight 1s 1s forwards;
}

@keyframes expandWidth {
  0% {
  }

  100% {
    width: 80%;
  }
}

@keyframes expandHeight {
  0% {
  }

  100% {
    max-height: 50%;
  }
}

#root.expand-complete h1,
#root.expand-complete p {
  opacity: 1;
}
